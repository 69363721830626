<template>
  <component :is="commonComponent" id="privacy" title="Privacy policy">
    <section id="privacy-content" class="pc_padding">
      <h1>Privacy policy</h1>

      <div class="common-content-row">
        Welcome to peoplelife.lifestyle. Your privacy is important to us. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your information when you visit our
        website. Please read this policy carefully. If you do not agree with the terms of this
        privacy policy, please do not access the site.
      </div>

      <div class="common-content-head">
        Information We Collect
      </div>

      <div class="common-content-row">
        We may collect information about you in a variety of ways. The information we may collect on
        the Site includes:
      </div>

      <div class="common-content-row">
        <ul>
          <li>
            <strong>Personal Data:</strong> Personally identifiable information, such as your
            name, email address, and any other information you voluntarily give to us when you
            register with the Site or when you choose to participate in various activities related
            to the Site, such as comments and feedback.
          </li>
          <li>
            <strong>Derivative</strong> <strong>Data:</strong> Information our servers
            automatically collect when you access the Site, such as your IP address, your browser
            type, your operating system, your access times, and the pages you have viewed directly
            before and after accessing the Site.
          </li>
          <li>
            <strong>Mobile Device Data:</strong> Device information, such as your mobile device
            ID, model, and manufacturer, and information about the location of your device, if you
            access the Site from a mobile device.
          </li>
        </ul>
      </div>
      <div class="common-content-head">
        Use of Your Information
      </div>

      <div class="common-content-row">
        Having accurate information about you permits us to provide you with a smooth, efficient,
        and customized experience. Specifically, we may use information collected about you via the
        Site to:
      </div>
      <div class="common-content-row">
        <ul>
          <li>Create and manage your account.</li>
          <li>
            Send you technical notices, updates, security alerts, and support and administrative
            messages.
          </li>
          <li>Respond to your comments, questions, and requests and provide customer service.</li>
          <li>
            Monitor and analyze trends, usage, and activities in connection with our services.
          </li>
          <li>
            Personalize and improve the Site and provide advertisements, content, or features that
            match user profiles or interests.
          </li>
          <li>
            Facilitate contests, sweepstakes, and promotions, and process and deliver entries and
            rewards.
          </li>
        </ul>
      </div>
      <div class="common-content-head">
        Disclosure of Your Information
      </div>

      <div class="common-content-row">
        We may share information we have collected about you in certain situations. Your information
        may be disclosed as follows:
      </div>
      <div class="common-content-row">
        <ul>
          <li>
            <strong>By Law or to Protect Rights:</strong> If we believe the release of information
            about you is necessary to respond to legal process, to investigate or remedy potential
            violations of our policies, or to protect the rights, property, and safety of others, we
            may share your information as permitted or required by any applicable law, rule, or
            regulation.
          </li>
          <li>
            <strong>Business Transfers:</strong> We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to another company.
          </li>
          <li>
            <strong>Third-Party Service Providers:</strong> We may share your information with
            third parties that perform services for us or on our behalf, including data analysis,
            email delivery, hosting services, customer service, and marketing assistance.
          </li>
          <li>
            <strong>Marketing Communications:</strong> With your consent, or with an opportunity
            for you to withdraw consent, we may share your information with third parties for
            marketing purposes, as permitted by law.
          </li>
          <li>
            <strong>Online Postings:</strong> When you post comments, contributions, or other
            content to the Site, your posts may be viewed by all users and may be publicly
            distributed outside the Site in perpetuity.
          </li>
          <li>
            <strong>Third-Party Advertisers:</strong> We may use third-party advertising companies
            to serve ads when you visit the Site. These companies may use information about your
            visits to the Site and other websites that are contained in web cookies in order to
            provide advertisements about goods and services of interest to you.
          </li>
        </ul>
      </div>
      <div class="common-content-head">
        Security of Your Information
      </div>

      <div class="common-content-row">
        We use administrative, technical, and physical security measures to help protect your
        personal information. While we have taken reasonable steps to secure the personal
        information you provide to us, please be aware that despite our efforts, no security
        measures are perfect or impenetrable, and no method of data transmission can be guaranteed
        against any interception or other type of misuse.
      </div>

      <div class="common-content-head">
        Policy for Children
      </div>

      <div class="common-content-row">
        We do not knowingly solicit information from or market to children under the age of 13. If
        we learn that we have collected personal information from a child under age 13 without
        verification of parental consent, we will delete that information as quickly as possible. If
        you believe we might have any information from or about a child under 13, please contact us.
      </div>

      <div class="common-content-head">
        Changes to This Privacy Policy
      </div>

      <div class="common-content-row">
        We may update this Privacy Policy from time to time in order to reflect changes to our
        practices or for other operational, legal, or regulatory reasons. We will notify you of any
        changes by posting the new Privacy Policy on our Site. You are advised to review this
        Privacy Policy periodically for any changes.
      </div>

      <div class="common-content-head">
        Contact Us
      </div>

      <div class="common-content-row">
        If you have questions or comments about this Privacy Policy, please contact us at:
      </div>

      <div class="common-content-row">
        Email: <strong>peoplelife.support@gmail.com</strong>
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Privacy policy | peoplelife.lifestyle',
      meta: [
        {
          name: 'description',
          content: 'Review the Privacy Policy for peoplelife.lifestyle. Learn how we handle user-uploaded content and data collected through Firebase and advertising services.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>